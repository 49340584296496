import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/circleci/choice/src/components/MdxLayout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <div {...{
      "isBox": true,
      "flipKey": 93
    }}><h1 parentName="div">{`Erklärung zur digitalen Barrierefreiheit`}</h1>
      <p parentName="div">{`Wir sind jederzeit bemüht, diesen Webauftritt so barrierefrei wie möglich zu gestalten. Vorgaben dazu werden im Gesetz über die barrierefreie Informations- und Kommunikationstechnik Berlin (BIKTG Bln) festgeschrieben. Die technischen Anforderungen zur Barrierefreiheit ergeben sich aus der BITV 2.0.`}</p>
      <p parentName="div">{`Stand der Barrierefreiheit des Angebots:`}<br parentName="p"></br>{`
`}{`Die letzte Überprüfung wurde am 27.10.2020 durchgeführt. Dabei wurde festgestellt, dass Teile des Webauftritts nicht vollständig den Anforderungen entsprechen. Dazu gehört:`}</p>
      <blockquote parentName="div">
        <p parentName="blockquote"><strong parentName="p">{`Sprache:`}</strong>{` Nicht alle Unterseiten sind auf Englisch & Türkisch übersetzt. Es wird auf die Verwendung einfacher Sprache geachtet. Da das Hauptanliegen dieser Seite ist, eine Forschungsgruppe und deren wissenschaftlichen Ergebnisse vorzustellen, kann auf die Verwendung von Fachsprache nicht immer verzichtet werden.`}<br parentName="p"></br>{`
`}<strong parentName="p">{`Kontraste:`}</strong>{` In seltenen Fällen kann es aufgrund des verwendeten Geräts (schlechte auflösendes oder kleines Display) sowie externer Faktoren wie Sonneneinstrahlung dazu kommen, dass der Kontrast zwischen Schrift und Hintergrund nicht optimal dargestellt wird und die Lesbarkeit einzelner Textteile eingeschränkt ist.`}</p>
      </blockquote>
      <p parentName="div">{`Hinweise auf weitere nicht-barrierefreie Inhalte können Sie senden an:`}</p>
      <blockquote parentName="div">
        <p parentName="blockquote">{`Dr. Tanja Mayer`}<br parentName="p"></br>{`
`}{`Humboldt-Universität zu Berlin`}<br parentName="p"></br>{`
`}{`Institut für Erziehungswissenschaften`}<br parentName="p"></br>{`
`}{`Arbeitsbereich Systematische Didaktik / Empirische Schul- und Unterrichtsforschung`}<br parentName="p"></br>{`
`}{`Unter den Linden 6`}<br parentName="p"></br>{`
`}{`10099 Berlin`}<br parentName="p"></br>{`
`}<a parentName="p" {...{
            "href": "mailto:tanja.mayer@hu-berlin.de"
          }}>{`tanja.mayer@hu-berlin.de`}</a></p>
      </blockquote>
    </div>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      